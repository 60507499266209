<template>
  <div class="has-nav-bar view-user-password-edit">
    <van-nav-bar fixed left-arrow title="修改密码" @click-left="onClickLeft" />
    <van-form ref="form" :show-error-message="true" @submit="onSubmit">
      <van-field
        v-model="info.oldPassword"
        :error-message="errMsg.oldPassword"
        name="oldPassword"
        placeholder="请输入原密码"
        type="password"
        @blur="checkOldPassword"
      />
      <van-field
        v-model="info.newPassword"
        :error-message="errMsg.newPassword"
        name="newPassword"
        placeholder="至少6位英文、数字组合,区分大小写"
        type="password"
        @blur="checkNewPassword"
      />
      <van-field
        v-model="info.confirmPassword"
        :error-message="errMsg.confirmPassword"
        name="confirmPassword"
        placeholder="请再次输入新密码"
        type="password"
        @blur="checkConfirmPassword"
      />
      <div class="support-mobile">
        <van-button
          :disabled="loading.status"
          :loading="loading.status"
          :loading-text="loading.text"
          block
          native-type="submit"
          round
          type="info"
          >确认修改</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  Form,
  Field,
  Toast,
  NavBar,
  Cell,
  Tag,
  Tabs,
  Tab,
  List,
  SwipeCell,
  Button,
  Icon,
  PullRefresh,
  Popup,
  RadioGroup,
  Radio
} from "vant";
import messageApi from "../../api/message";
import authApi from "@/api/authorization";
import { mapState } from "vuex";
import loginUtil from "../../utils/login";
import { loading } from "@/utils/constant";

export default {
  name: "UserPasswordEdit",
  components: {
    [NavBar.name]: NavBar,
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [SwipeCell.name]: SwipeCell,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [List.name]: List,
    [Form.name]: Form,
    [Field.name]: Field,
    [Toast.name]: Toast
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  data() {
    return {
      info: {},
      loading: { ...loading },
      errMsg: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      }
    };
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    preventKeyboard() {
      document.activeElement.focus();
    },
    checkOldPassword() {
      if (!this.info.oldPassword) {
        this.errMsg.oldPassword = "请输入密码";
        return false;
      } else if (!/^[0-9A-Za-z]{6,}$/.test(this.info.oldPassword)) {
        this.errMsg.oldPassword = "密码格式错误";
        return false;
      } else {
        this.errMsg.oldPassword = "";
        return true;
      }
    },
    checkNewPassword() {
      if (!this.info.newPassword) {
        this.errMsg.newPassword = "请输入密码";
        return false;
      } else if (!/^[0-9A-Za-z]{6,}$/.test(this.info.newPassword)) {
        this.errMsg.newPassword = "密码格式错误";
        return false;
      } else {
        this.errMsg.newPassword = "";
        return true;
      }
    },
    checkConfirmPassword() {
      if (!this.info.confirmPassword) {
        this.errMsg.confirmPassword = "请再次输入密码";
        return false;
      } else if (this.info.confirmPassword !== this.info.newPassword) {
        this.errMsg.confirmPassword = "两次输入的密码不一致";
        return false;
      } else {
        this.errMsg.confirmPassword = "";
        return true;
      }
    },
    onSubmit(values) {
      console.log("submit", this.info, values);
      if (
        this.checkOldPassword() &&
        this.checkNewPassword() &&
        this.checkConfirmPassword()
      ) {
        this.loading.status = true;

        authApi
          .updateAuthorizationUpdateUsersPasswordName(this.info)
          .then(res => {
            console.log("respw1111111111111", res);
            Toast.success("密码重置成功!请重新登录");
            loginUtil.removeToken();
            setTimeout(() => {
              // this.$router.push({ name: "Login" });
              if (window.APP.getPlatform().isAndroid) {
                window.android.logout();
              } else {
                location.href = "/";
              }
            }, 2000);
          })
          .catch(err => {
            console.log("onSubmit -> err", err);
          })
          .finally(() => {
            this.loading.status = false;
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.view-user-password-edit {
  .van-form {
    overflow: auto;
    padding: 20px;
  }
  .support-mobile {
    margin: 6vh 0;
  }
}
</style>
